//WEBSITE URLS
export const WEBSITE_URLS = {
    SIGNIN: "/signin",
    CHANGE_PASSWORD: "/change-password",
    FORGOT: "/forgot-password",
    DASHBOARD: "/dashboard",
    SETTINGS: "/settings",
    NOT_FOUND: "/404",
};

// API URLS
export const API_URLS = {
    LOGIN: "auth/admin/sign-in",
    LOGOUT: "auth/sign-out",

    //ADMIN CHANGE PASSWORD
    CHANGE_PASSWORD: "auth/change-password",

    //ADMIN FORGOT PASSWORD
    FORGOT_PASSWORD: "auth/admin-forgot-password",

    //USer LISTING
    GET_USER_LIST: "user/list",

    //USER DELETE
    DELETE_USER: "user/delete-user",

    //CONTACT DELETE
    DELETE_CONTACT: "contact/delete-contact",

    //USER EDIT
    EDIT_USER: "user/profile",

    //Static Content
    GET_STATIC_CONTENT_LIST: "page/list",

    //Edit Static Content
    EDIT_STATIC_CONTENT: {
        'about-us'         :    'page/about-us',
        'rights-statelaws' :    'page/rights-statelaws',
        'terms-conditions' :    'page/terms-conditions',
        'privacy-policy'   :    'page/privacy-policy'  
    },

    //SOS LISTING
    GET_SOS_LIST: "contact/list",

    //SOS LISTING
    EDIT_SOS: "contact/edit",

    //LiveStreaming LISTING
    GET_LIVE_STREAMING_LIST: "liveSessions/livesession-list",

    //VIDEO DELETE
    DELETE_VIDEO: "liveSessions/delete-video",

    //CONTACT COUNT
    COUNT_CONTACT: "contact/contact-list",

    //STREAMING COUNT
    COUNT_STREAMING: "liveSessions/admin-livesession-list",

    // GET STREAMING LIST ALL
    GET_STREAMING_LIST_ALL: "streaming/list/all"
}