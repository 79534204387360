import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '/users',
    component: asyncComponent(() => import('../Users/Users')),
    exact: false
  },
  {
    path: '/manage-sos',
    component: asyncComponent(() => import('../Sos/Sos')),
    exact: false
  },
  {
    path: '/manage-livestreaming',
    component: asyncComponent(() => import('../LiveStreaming/LiveStreaming')),
    exact: true
  },
  {
    path: '/manage-livestreaming/view-live-streaming/:id',
    component: asyncComponent(() => import('../LiveStreaming/ViewLiveStreaming')),
    exact: true
  },
  {
    path: '/templates',
    component: asyncComponent(() => import('../Category/Category')),
    exact: false
  },
  {
    path: '/settings',
    component: asyncComponent(() => import('../Settings/Settings')),
    exact: false
  },
  {
    path: '/static-content',
    component: asyncComponent(() => import('../StaticContent/StaticContent')),
    exact: false
  },
  {
    path: '/change-password',
    component: asyncComponent(() => import('../ChangePassword/ChangePassword')),
    exact: false
  },
  {
    path: '/404',
    component: asyncComponent(() => import('../Page/404')),
    exact: false
  },
  {
    path: '/dashboard',
    component: asyncComponent(() => import('../Widgets/index.js')),
    exact: true
  },
  {
    path: '/',
    component: asyncComponent(() => import('../Widgets/index.js')),
    exact: false
  },
];

class AppRouter extends Component {
  render() {
    const { style } = this.props;
    return (
      <div style={style}>
        <Switch>
          {routes.map(singleRoute =>
            <Route
              exact={singleRoute.exact}
              key={singleRoute.path}
              path={singleRoute.path}
              {...singleRoute}
            />
          )}
        </Switch>
      </div>
    );
  }
}

export default AppRouter;
