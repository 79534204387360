const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (data) => {
    localStorage.setItem('authToken', data.authToken);
    localStorage.setItem('id', data.id);
    return {
      type: actions.LOGIN_SUCCESS,
      ...data
    }
  },
  logout: () => {
    return {
      type: actions.LOGOUT
    }
  }
};
export default actions;
