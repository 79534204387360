const options = [
  {
    key: 'Dashboard',
    label: 'sidebar.dashboard',
    leftIcon: 'ion-podium',
  },
  {
    key: 'Users',
    label: 'sidebar.users',
    leftIcon: 'ion-ios-people',
  },
  // {
  //   key: 'manage-sos',
  //   label: 'sidebar.manageSos',
  //   leftIcon: 'ion-ios-list',
  // },
  {
    key: "static-content",
    label: "sidebar.staticContent",
    leftIcon: "ion-ios-paper"
  },
  {
    key: 'manage-livestreaming',
    label: 'sidebar.manageLivestreaming',
    leftIcon: 'ion-ios-list',
  },
  /*{
    key: 'manage-sub-category',
    label: 'sidebar.manageSubCategory',
    leftIcon: 'ion-ios-list',
  },*/
  // {
  //   key: "settings",
  //   label: "sidebar.settings",
  //   leftIcon: "ion ion-wrench"
  // },
  {
    key: "change-password",
    label: "sidebar.changePassword",
    leftIcon: "ion-ios-settings"
  },

];
export default options;
